<template>
  <section id="surgery-status-container">
    <StatusSelect 
      :statusTime="surgery?.updated_at" 
      :status="surgery.status" 
      @change-status="updateSurgeryStatus"
    />
    <LogsModal :logId="surgery?.id" logType="surgery-information" />
  </section>
</template>

<script>
import { getSurgeryStatus } from '@/utils/surgeryCenterHelper'

export default {
  name: 'SurgeryStatusUpdate',
  props: {
    surgery: { type: Object, required: true },
  },
  components: {
    StatusSelect: () => import('@/components/SurgeryCenter/SurgeryInformation/StatusSelect.vue'),
    LogsModal: () => import('@/components/Logs/LogsModal')
  },
  data () {
    return {
      updatingStatus: false,
    }
  },
  computed: {
    status: function () { 
      const stts = getSurgeryStatus() 
      return stts.map((status) => ({ value: status, label: status }))
    },
  },
  methods: {
    async updateSurgeryStatus (status) {
      try {
        this.updatingStatus = true
        this.surgery.status = status
        await this.api.updateSurgeryInformation(this.surgery.id, { status: status })
        this.surgery.updated_at=this.moment.now()
      } catch (error) {
        this.$toast.error('Não foi possível atualizar o status da cirurgia, contate o suporte se o erro persistir.')
      } finally {
        this.updatingStatus = false
      }
    },
  },

}
</script>

<style lang="scss">
#surgery-status-container {
  background-color: var(--neutral-000);
  width: 100%;
  padding: 15px
}
</style>